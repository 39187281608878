<template>
  <v-app>
    <v-row class="d-flex">
      <v-col cols="12" lg="7" xl="6" class="primary d-flex align-center justify-center">
        <v-container>
          <div class="pa-10">
            <v-row justify="center">
              <v-col cols="10" md="8" xl="5">
                <div class="d-flex align-center align-md-start justify-start flex-column">
                  <img src="@/assets/images/logo/logo-pruvo-white.png" class="logo-pruvo" />
                  <div class="tw-hidden md:tw-block">
                    <h6
                      class="tw-hidden md:tw-block subtitle-1 mt-4 white--text op-5 font-weight-regular"
                    >
                      Olá! Você está a poucos passos de contar com a praticidade
                      da nossa ferramenta. Com a opção
                      de integração do Pruvo com a plataforma Google for
                      Education, facilitamos diariamente a vida de milhares de
                      professores, alunos e instituições.
                    </h6>
                    <a href="https://pruvo.app">
                      <v-btn class="mt-4 text-capitalize" x-large outlined color="white">Saiba Mais</v-btn>
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
        <v-container>
          <div class="pa-7 pa-sm-12">
            <v-row>
              <v-col cols="12" lg="9" xl="6">
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Faça seu login</h2>
                <!-- <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Crie uma nova conta</h2> -->
                <h6 class="subtitle-1">
                  Utilize sua conta institucional.
                  <!-- Não possui uma conta? -->
                  <!-- <router-link to="/register">Cadastre-se</router-link> -->
                </h6>

                <div class="text-center mt-6 mb-10">
                  <v-btn color="primary" depressed class="button-google" @click="loginGoogle()">
                    <img src="@/assets/images/logo/g-logo.png" rounded class="icon-google" />
                    <span class="text-google font-weight-medium">Entrar com o Google</span>
                  </v-btn>
                </div>
                <v-row align="center" class="mb-6 px-3">
                  <v-divider />
                  <span class="mx-2" style="color: #00000099">ou</span>
                  <v-divider />
                </v-row>
                <v-form @keyup.native.enter="submit" ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="form.email"
                    :rules="[rules.email, rules.required]"
                    validate-on-blur
                    label="E-mail"
                    class="mt-4"
                    name="email"
                    dense
                    required
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="form.password"
                    :rules="[rules.password]"
                    validate-on-blur
                    label="Senha"
                    dense
                    name="password"
                    required
                    outlined
                    @click:append="() => (show1 = !show1)"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  ></v-text-field>
                  <div v-show="loginAttemptRole()" class="mb-5 tw-flex tw-justify-center">
                    <vue-recaptcha
                      @verify="onVerify"
                      @expired="onExpired"
                      ref="recaptcha"
                      :sitekey="sitekey"
                    />
                  </div>
                  <v-btn
                    :disabled="!valid"
                    color="info"
                    block
                    class="mr-4"
                    submit
                    outlined
                    @click="submit"
                  >Entrar</v-btn>
                </v-form>
                <div class="mt-5 text-center">
                  <a @click="$router.push('/password/forgot')">
                    <small>
                      Esqueci minha senha
                    </small>
                  </a>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex'
import { VueRecaptcha } from 'vue-recaptcha'
import baseAPI from '@/libs/baseApi.js'

export default {
  components: { VueRecaptcha },
  data: () => ({
    valid: true,
    show1: false,
    form: {
      email: '',
      password: ''
    },
    rules: {
      required: v => (v && !!v.trim()) || 'Este campo é obrigatório.',
      email: v => {
        if (!/.+@.+\..+/.test(v)) return 'O e-mail deve ser válido.'
        if (v.indexOf(' ') >= 0) return 'Não são permitidos espaços.'
        return true
      },
      password: v => (v && !!v.trim()) || 'A senha é obrigatória.'
    },
    checkbox: false,
    sitekey: '6LcUV-gjAAAAACVoa9T8H1RF7T_S4Au967yEYL7L',
    tokenRecaptcha: '',
    loginAttempts: 0,
    maxLoginAttempts: 1
  }),
  methods: {
    ...mapMutations(['setLoading']),
    onVerify (response) {
      this.tokenRecaptcha = response
    },
    clearTokenRecaptcha () {
      this.tokenRecaptcha = ''
    },
    onExpired () {
      this.clearTokenRecaptcha()
    },
    resetRecaptcha () {
      this.clearTokenRecaptcha()
      this.$refs.recaptcha.reset() // Direct call reset method
    },
    validateRecaptcha () {
      if (!this.tokenRecaptcha) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Preencha o recaptcha'
        })
      }
      return this.tokenRecaptcha
    },
    addLoginAttempt () {
      this.loginAttempts += 1
    },
    loginAttemptRole () {
      return this.loginAttempts >= this.maxLoginAttempts
    },
    async submit () {
      this.$refs.form.validate()
      if (this.$refs.form.validate(true)) {
        if (this.loginAttemptRole() && !this.validateRecaptcha()) {
          return
        }
        this.addLoginAttempt()
        try {
          this.setLoading(true)

          const request = await this.$axios.post('/auth/login', this.form)
          const token = request.data.access_token
          await this.tokenLogin(token)
        } catch (error) {
          this.resetRecaptcha()
          this.setLoading(false)

          let msg = 'Erro na conexão, tente novamente'
          if (error.response.data.error === 'Unauthorized') {
            msg = 'Email ou senha incorretos'
          }
          this.$store.dispatch('alert', { color: 'red', msg })
        }
        // finally {
        //   this.setLoading(false)
        // }
      } else {
        this.$store.dispatch('alert', { color: 'red', msg: 'Preencha todos os campos de login' })
      }
    },
    async tokenLogin (token) {
      this.setLoading(true)

      localStorage.setItem('jwtToken', token)
      this.$axios.defaults.headers.Authorization = 'bearer ' + token
      await this.$router.push({ path: '/' })
    },
    loginGoogle () {
      const newWindow = this.openWindow('', 'message')
      newWindow.location.href = baseAPI + '/auth/oauth/google'
    },
    // This method save the new token and username
    onMessage (e) {
      // console.log('onmessage', e)
      // console.log('token', e.data.access_token)
      // console.log('oringin 1', e.origin)
      // console.log('oringin 2', window.origin)
      // if (e.origin !== window.origin || !e.data.access_token) {
      //   return
      // }
      if (!e.data.access_token) {
        return
      }

      this.tokenLogin(e.data.access_token)
    },
    openWindow (url, title, options = {}) {
      if (typeof url === 'object') {
        options = url
        url = ''
      }

      options = { url, title, width: 600, height: 720, ...options }

      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
      const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
      const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

      options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
      options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

      const optionsStr = Object.keys(options).reduce((acc, key) => {
        acc.push(`${key}=${options[key]}`)
        return acc
      }, []).join(',')

      const newWindow = window.open(url, title, optionsStr)

      if (window.focus) {
        newWindow.focus()
      }

      return newWindow
    }

  },
  mounted () {
    window.addEventListener('message', this.onMessage, false)
  },

  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
  }
}
</script>
<style scoped>
.logo-pruvo {
  max-width: 200px;
}
.button-google {
  height: 50px !important;
  padding: 1px 1px !important;
  width: 100% !important;
}
.icon-google {
  position: absolute;
  left: 0;
  width: auto;
  height: 48px;
  margin-right: 24px;
  background-color: #fff;
  padding: 12px 12px !important;
  border-radius: 3px;
}
.text-google {
  font-size: 16px;
  margin-left: 22px;
}
</style>
